import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  useLocation
} from 'react-router-dom';
import '@eightfold.ai/octuple/lib/octuple.css';
import WelcomePage from '../pages/WelcomePage';
import Onboarding from '../pages/onboarding/Onboarding';
import DashBoard from '../pages/DashBoard';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './config';
import { RequiredAuth } from './RequiredAuth';
import InviteExpired from '../pages/InviteExpired';
import IdleTimer from '../IdleTime';
import { ShowLogout } from '../commons/commonFunctions';
import InviteRaters from '../pages/raters/InviteRaters';
import InviteVerify from '../pages/InviteVerify';
import ErrorPage from '../pages/ErrorPage';
import VerifyEmail from '../pages/VerifyEmail';



const okatAuth = new OktaAuth(config.oidc);
const AppRouter = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const restoreOriginalUri = async (_okatAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '', window.location.origin), {
      replace: true,
    });
  }

  const titleDictionary: any = {
    '/': 'Heidrick Digital | Welcome',
    '/welcome': 'Heidrick Digital | Welcome',
    '/register-account': 'Heidrick Digital | Register | Account',
    '/register-role': 'Heidrick Digital | Register | Role',
    '/register-company': 'Heidrick Digital | Register | Company',
    '/register-personal': 'Heidrick Digital | Register | Personal',
    '/login': 'Heidrick Digital | Login',
    '/forgot-password': 'Heidrick Digital | Forgot Password',//NOSONAR
    '/invite-expired': 'Heidrick Digital | Invite Expired',
  };

  useEffect(() => {
    let currentPath = location.pathname;
    document.title = titleDictionary[currentPath] || 'Heidrick Digital';
  }, [location]);

  return (
    <Security
      oktaAuth={okatAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route element={<RequiredAuth />}>
          <Route path="*" element={<WelcomePage />} />
          <Route path='/welcome' element={<WelcomePage />} />
          <Route path='/register-account' element={<Onboarding />} />
          <Route path='/register-role' element={<Onboarding />} />
          <Route path='/register-company' element={<Onboarding />} />
          <Route path='/register-personal' element={<Onboarding />} />
          <Route path='/dashboard' element={<DashBoard />} />
          <Route path='/invite-raters' element={<InviteRaters />} />
          <Route path='/error' element={<ErrorPage />} />


        </Route>
        <Route
          path={'/login/callback'}
          element={
            <LoginCallback
              errorComponent={() => {
                localStorage.removeItem('okta-original-uri-storage');
                localStorage.removeItem('okta-cache-storage');
                localStorage.removeItem('okta-shared-transaction-storage');
                localStorage.removeItem('okta-token-storage');
                navigate('/');
                return null;
              }}
            />
          }
        />
        <Route
          path={'/invite-expired'}
          element={<InviteExpired />}
        />
        <Route
          path={'/invitation-verification'}
          element={<InviteVerify />}
        />
        <Route
          path={'/verify-email'}
          element={<VerifyEmail />}
        />
      </Routes>
      <IdleTimer />
      <ShowLogout />
    </Security>
  )
}

export default AppRouter;