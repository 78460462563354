import React, { useContext, useState } from "react";
import {
  Button,
  ButtonVariant,
  IconName,
  ButtonIconAlign,
} from "@eightfold.ai/octuple";
import Header from "../commons/Header";
import { secondaryButtonStyle } from "../commons/commonStyles";
import Footer from "../commons/Footer";
import { globalContext } from "../store";
import { getOnboardingImg, getHeaderLogo } from "../commons/commonFunctions";
import { useOktaAuth } from "@okta/okta-react";

const ErrorPage = () => {
  const { oktaAuth } = useOktaAuth();
  const { globalState } = useContext(globalContext);
  const [showLoader, setShowLoader] = useState(false);
  const title = globalState?.clientData?.name;

  const logOutClick = () => {
    setShowLoader(true);
    sessionStorage.clear();
    if (oktaAuth) {
      oktaAuth.signOut({
        postLogoutRedirectUri: window.location.origin,
      });
    }
  };

  return (
    <>
    <div className="mainWrapper">
      <div
        className="imgWrapper"
        style={{
          background: `url(${getOnboardingImg(globalState)}) no-repeat`,
        }}
      >
        &nbsp;
      </div>
      <div className="rightWrapper">
        <Header title={title} headerLogo={getHeaderLogo(globalState)} />
        <div className="contentWrapper" style={{alignItems: 'center'}}>
            <h1  className="titleStyle" >You don't have any client associated</h1>          
          <div style={{marginTop:'10px'}}>
            <div>
              <Button
                text="Log Out"
                variant={ButtonVariant.Secondary}
                classNames="secondaryButton"
                style={secondaryButtonStyle}
                onClick={() => {
                  logOutClick();
                }}
                iconProps={
                  showLoader
                    ? { path: IconName.mdiLoading, spin: true }
                    : undefined
                }
                alignIcon={ButtonIconAlign.Right}
                disabled={showLoader}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
    </>
  );
};

export default ErrorPage;
