import { Button, ButtonVariant, CheckBox, LabelSize, TextInput, TextInputSize, TextInputWidth, TextInputIconAlign, IconName, SelectorSize } from "@eightfold.ai/octuple";
import React, { useState } from "react";

const GenerateRow = (props: any) => {
  const changeRowData =(value:any, key:any, errorKey:any, sectionKey:any)=>{
    let newFields = { ...props?.allData?.fieldsData };
    newFields[sectionKey].rows[props.index][key] = value;
    props?.allData?.fieldState(newFields);   
  }

  const checkRequired = (props?.data?.errors?.firstNameErrMsg || 
    props?.data?.errors?.lastNameErrMsg ||  props?.data?.errors?.emailErrMsg || props?.data?.disableRow)  
      || ( props?.data?.firstName && props?.data?.lastName && props?.data?.email )||
      (  props?.data?.firstName || props?.data?.lastName || props?.data?.email)
  return (
    <div className="sectionRow" key={props?.key}>
      <div className="sectionColumn columnLabel">
        {props?.rowLabel} 
        {checkRequired
           ? '*'  : '' }
      </div>
      <div className="sectionColumn">
        <TextInput
          htmlType="text"
          id="firstName"
          clearable={!props?.data?.disableRow}
          allowDisabledFocus={props?.data?.disableRow}
          labelProps={{
            text: `First Name${checkRequired ? '*' : ''}`,
            size: LabelSize.Medium,
          }}
          onChange={(e: any) => {
            changeRowData(e.target.value, 'firstName','firstNameErrMsg', props?.sectionKey)
          }}
          maxlength={100}
          minlength={0}
          name="firstName"
          value={props?.data?.firstName}
          inputWidth={TextInputWidth.fill}
          classNames="inputTextField"
          size={TextInputSize.Large}
          disabled={props?.disableSection}
          iconProps={props?.data?.disableRow && {path:IconName.mdiLock}}
          alignIcon={props?.data?.disableRow  && TextInputIconAlign.Right }          
          status={props?.data?.errors?.firstNameErrMsg ? 'error' : 'validating'}

        />
        {props?.data?.errors?.firstNameErrMsg && <span className="errorMessage">{props?.data?.errors?.firstNameErrMsg}</span>}

      </div>
      <div className="sectionColumn">
        <TextInput
          htmlType="text"
          id="lastName"
          clearable={!props?.data?.disableRow}
          allowDisabledFocus={props?.data?.disableRow}
          labelProps={{
            text: `Last Name${checkRequired? '*' : ''}`,
            size: LabelSize.Medium,
          }}
          onChange={(e: any) => {
            changeRowData(e.target.value, 'lastName', 'lastNameErrMsg', props?.sectionKey)
          }}
          iconProps={props?.data?.disableRow && {path:IconName.mdiLock}}
          maxlength={100}
          minlength={0}
          name="lastName"
          value={props?.data?.lastName}
          inputWidth={TextInputWidth.fill}
          classNames="inputTextField"
          size={TextInputSize.Large}
          disabled={props?.disableSection}
          alignIcon={props?.data?.disableRow && TextInputIconAlign.Right}
          status={props?.data?.errors?.lastNameErrMsg ? 'error' : 'validating'}

        />
        {props?.data?.errors?.lastNameErrMsg && <span className="errorMessage">{props?.data?.errors?.lastNameErrMsg}</span>}

      </div>
      <div className="sectionColumn">
        <TextInput
          htmlType="text"
          id="email"
          clearable={!props?.data?.disableRow}
          allowDisabledFocus={props?.data?.disableRow}
          labelProps={{
            text: `Email${checkRequired? '*' : ''}`,
            size: LabelSize.Medium,
          }}
          iconProps={props?.data?.disableRow && {path:IconName.mdiLock}}
          onChange={(e: any) => {
            changeRowData(e.target.value, 'email', 'emailErrMsg', props?.sectionKey)
          }}
          maxlength={100}
          minlength={0}
          name="email"
          value={props?.data?.email}
          inputWidth={TextInputWidth.fill}
          classNames="inputTextField"
          size={TextInputSize.Large}
          disabled={props?.disableSection}
          alignIcon={props?.data?.disableRow && TextInputIconAlign.Right}
          status={props?.data?.errors?.emailErrMsg ? 'error' : 'validating'}

        />
        {props?.data?.errors?.emailErrMsg && <span className="errorMessage">{props?.data?.errors?.emailErrMsg}</span>}

      </div>
    </div>
  )
}

const GenerateSection = (props: any) => {
  const fieldsRecordsData = props?.fieldsData;
  const [buttonStates, setButtonStates] = useState<any>({});

  const addNewrow = (sectionId: string) => {
    const previousData = { ...props?.fieldsData };
    const previousRows = [...previousData?.[sectionId]?.rows];
    const otherDetails = previousData?.[sectionId]?.otherDetails; 
    if (previousRows.length >= otherDetails?.max) {
      setButtonStates((prevState:any) => ({ ...prevState, [sectionId]: true }));  
      return;  
    }
    previousRows.push({ firstName: '', lastName: '', email: '', disableRow:false });

    let newFields = { ...props?.fieldsData };
    newFields[sectionId]['rows'] = [...previousRows];
    props?.fieldState(newFields);
    if (previousRows.length >= otherDetails?.max) {
      setButtonStates((prevState:any) => ({ ...prevState, [sectionId]: true }));  
    }
    
  }


  const noMembersToProvide = (event: any, sectionId: string) => {
    let newFields = { ...props?.fieldsData };
    newFields[sectionId]['otherDetails']['disableSection'] = event?.target?.checked;
    setButtonStates((prevState:any) => ({ ...prevState, [sectionId]: event?.target?.checked }));
    props?.fieldState(newFields);
  }

  return (
    <>
      {
        fieldsRecordsData && Object.keys(fieldsRecordsData)?.map((item: any, index: any) => {
          const otherDetails = fieldsRecordsData?.[item]?.otherDetails;
          return (
            <div className="sectionWrapper" key={item}>
              <div className="sectionHeader">
                <div className="sectionTitleWrap">
                  <div className="sectionTitle">{item}</div>
                  <div className="sectionDesc">
                    Add at least {otherDetails?.min} {otherDetails?.sectionKey} as raters. For best results, we recommend {otherDetails?.recom}. If you have none, click checkbox.
                  </div>
                </div>
                <div className="sectionHeaderAction">
                  <CheckBox
                    ariaLabel="No team members to provide"
                    classNames="sectionChkBox"
                    configContextProps={{
                      noDisabledContext: false,
                      noSizeContext: false,
                      noThemeContext: false
                    }}
                    id="myCheckBoxId"
                    label={`No ${otherDetails?.sectionKey} to provide`}
                    size={SelectorSize.Large}
                    name="myCheckBoxName"
                    onChange={(event) => noMembersToProvide(event, otherDetails?.sectionKey)}
                  />
                </div>
              </div>
              <div className="sectionBody">
                {fieldsRecordsData?.[item]?.rows?.map((item: any, indn: any) => {
                  return (
                    <GenerateRow
                      key={`rowIndex${indn}`}
                      rowLabel={otherDetails?.sectionKey + " " + (indn + 1)}
                      disableSection={otherDetails?.disableSection}
                      data={item}
                      allData={props}
                      index={indn}
                      sectionKey={otherDetails?.sectionKey}
                    />
                  )
                })}
                <div className="sectionRow">

                  <div className="sectionBtnWrap">
                  <span className="apiErrorMessage">{otherDetails?.errMsg}</span>

                    <Button
                      text={`Add ${otherDetails?.sectionKey}`}
                      variant={ButtonVariant.SystemUI}
                      classNames="ratersButton"
                      onClick={() => addNewrow(otherDetails?.sectionKey)}
                      transparent={true}
                      disabled={buttonStates[item] || false}

                      iconProps={{ path: IconName.mdiPlus, color: '#BA3A19' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
    </>
  );
}

export default GenerateSection;