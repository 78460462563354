export const clientInfo:any = {
    'Hlabs': {
        headerImage:'./../assets/img/HlabsHeaderPreference2.jpg',
        welcomeText:'The Leader Portal is designed to assist leaders like you in your professional journey, as it is a centralized location where you can access all Heidrick assessments assigned to you. Heidrick & Struggles aims to provide valuable insights and help you understand various aspects of your leadership abilities.\nWithin the Leader Portal is access to your assessment(s) and a brief background information survey.\nWe recommend that you complete each assessment when you can be free from interruption. Please ensure that you complete both the background information survey and your assessment(s).\nIf you need technical assistance, please email our support team (support@heidrick.com), which is here to help.',
        leftImage:'./../assets/img/HlabsLeftPanelPreference2.jpg',
        headerLogo:'./../assets/img/HlabsHeaderLogo.png'
    },
    '*':{
        headerImage:'./../assets/img/HeaderBackground.png',
        welcomeText:'This platform is designed to assist leaders like you in your professional journey. Through our comprehensive assessments, we aim to provide valuable insights and help you understand various aspects of your leadership abilities.\nIf you need technical assistance, our support team is here to help.(support@heidrick.com)\nWe recommend that you complete each assessment section when you can be free from interruption. You may be assigned multiple assessment sections as part of this experience. Please ensure that you complete all assigned sections. No advanced preparation is required.',
        leftImage:'./../assets/img/onboarding-image.png', 
        headerLogo:'./../assets/img/HlabsHeaderLogo.png'
    }

};
