import axios from 'axios';
class Api {
  token: string | null | any;
  root: string | undefined;
  constructor() {
    this.token = sessionStorage.getItem('loginToken') ?? false;
    this.root = process.env.REACT_APP_PLATFORM_BASE_URL;
  }

  getAuthOptions() {
    this.token = sessionStorage.getItem('loginToken') ?? false;
    const { accessToken } = JSON.parse(this.token) || {}
    return {
      headers: {
        Authorization: `Token ${accessToken?.accessToken}`,
      },
    };
  }

  async getClientByDomain(payload: any) {
    try {
      const response = await axios.get(
        `${this.root}/clients/api/v2/clients?subDomain=${payload}`, this.getAuthOptions()
      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async getLeaderProfile(payload: any) {
    try {
      const response = await axios.get(
        `${this.root}/profile/api/v2/leader-profile?email=${encodeURIComponent(payload.email)}&clientId=${payload.clientId}`, this.getAuthOptions()
      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async updateLeaderProfile(payload: any, leaderProfileId: any) {
    try {
      const response = await axios.patch(
        `${this.root}/profile/api/v2/leader-profile/${leaderProfileId}`, payload, this.getAuthOptions()
      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async getLookUp(payload: any) {
    try {
      const response = await axios.get(
        `${this.root}/leadership/api/v2/lookups?clientId=${payload.clientId}`, this.getAuthOptions()
      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async getLeaderProjects(clientId: any, leaderProfileId: any) {
    try {
      const response = await axios.get(
        `${this.root}/projects/api/v2/assignments?clientId=${clientId}&leaderProfileId=${leaderProfileId}`, this.getAuthOptions()
      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async getLeaderAssessmentDetails(clientId: any, leaderProfileId: any) {
    try {
      const response = await axios.get(
        `${this.root}/assessments/api/v2/assessments?clientId=${clientId}&leaderProfileId=${leaderProfileId}`, this.getAuthOptions()
      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async getLeaderAssessmentStructureDetails(idsString: any) {
    try {
      const response = await axios.get(
        `${this.root}/assessmentstructure/api/v2/assessments?assessmentIdList=${idsString}`, this.getAuthOptions()
      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async getLeaderReports(leaderProfileId: any) {
    try {
      const response = await axios.get(
        `${this.root}/attachments/api/v2/attachments?leaderProfileId=${leaderProfileId}`, this.getAuthOptions()

      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async downloadLeaderReports(attachmentId: any, reportName: string) {
    const content_type_mapping: any = {
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/rtf': 'rtf',
      'text/plain': 'txt',
    };
    let options: any = this.getAuthOptions();
    options['responseType'] = 'blob';
    try {
      const response = await axios.get(`${this.root}/attachments/api/v2/attachments/${attachmentId}/download`, options);
      const url_2 = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url_2;
      const contentType = response.headers['content-type'];
      const fileExt = content_type_mapping[contentType];
      const fileName = reportName + '.' + fileExt;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async inviteVerify(payload: any ) {
    try {
      const response = await axios.post(
        `${this.root}/ssa/api/v2/invite-verify`, payload
      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }

  async getEmailVerify(payload: any,token:any ) {
    try {
      const response = await axios.post(
        `${this.root}/auth/api/v2/candidate-register`, payload , {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return { success: true, response };
    } catch (error: any) {
      const { response: response_2 } = error;
      const { data } = response_2;
      return { success: false, data };
    }
  }
}

export default new Api();
