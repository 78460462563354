import React, { useContext, useEffect, useState } from "react";
import AuthHeader from '../../commons/AuthHeader';
import AuthFooter from '../../commons/AuthFooter';
import { globalContext } from "../../store";
import { getHeaderLogo, getHeaderImg } from '../../commons/commonFunctions';
import { Button, ButtonIconAlign, ButtonVariant, Icon, IconName } from "@eightfold.ai/octuple";
import { secondaryButtonStyle } from "../../commons/commonStyles";
import GenerateSection from "./GenerateSection";
import { validateFields, validationOptions } from "../../ValidationSchema";

const InviteRaters = () => {

  const { globalState } = useContext(globalContext);
  const title = globalState?.clientData?.name;
  const [showLoader, setShowLoader] = useState(false);

  const dummyData = [
    { sectionKey: 'Team Member', min: 3, recom: 4, max: 6, disableSection: false },
    { sectionKey: 'Manager', min: 2, recom: 4, max: 7, disableSection: false },
    { sectionKey: 'Peers', min: 3, recom: 4, max: 7, disableSection: false },
    { sectionKey: 'External Raters', min: 3, recom: 4, max: 7, disableSection: false },
  ];

  const [ratersFieldsData, setRatersFieldsData] = useState<any>();
  const [minTotal, setMinTotal] = useState(0);
  const [error, setError] = useState('')

  useEffect(() => {
    const createInitial: any = [];
    dummyData.forEach((item: any, index: any) => {
      const rows = [];      
      for (let i = 0; i < item.recom; i++) {
        if (i == 0) {
          rows.push({ firstName: 'Test', lastName: 'Test Last', email: 'test@gmail.com', disableRow: false });
        } else {
          rows.push({ firstName: '', lastName: '', email: '', disableRow: false });
        }
      }
      const minTotal = dummyData.reduce((total, item) => total + item.min, 0);
      setMinTotal(minTotal);
      createInitial[item.sectionKey] = [];
      createInitial[item.sectionKey]['rows'] = rows;
      createInitial[item.sectionKey]['otherDetails'] = { ...dummyData[index] };
      setRatersFieldsData(createInitial);
    });
  }, []);

  const rowErrorSet = (row:any) => {
    row.errors.firstNameErrMsg = validateFields.validate({ textField: row.firstName || '' }, validationOptions)?.error?.details[0]?.message;
    row.errors.lastNameErrMsg = validateFields.validate({ textField: row.lastName || '' }, validationOptions)?.error?.details[0]?.message;
    row.errors.emailErrMsg = validateFields.validate({ email: row.email || '' }, validationOptions)?.error?.details[0]?.message; 
 
  }

  const validateRows = (rows:any, otherdetails:any)=> {
    let count = 0;
    
     let filledRows :any = rows.filter((row:any) => row.firstName || row.lastName  || row.email).length;
     count = filledRows
      rows.forEach((row: any) => {
        const isFilled = row.firstName || row.lastName || row.email;
        if (!row.errors) {
          row.errors = {};
        }
       
        if (count < otherdetails.min && !isFilled) {
          rowErrorSet(row);
          count = count +1; 
        }else {
          if(isFilled){
            rowErrorSet(row); 
          } else {
            row.errors.firstNameErrMsg = '';
            row.errors.lastNameErrMsg = '';
            row.errors.emailErrMsg = '';
          }
        }
   
        if (isFilled) {
          rowErrorSet(row); 
        }
   
      });
    const newFields:any = {...ratersFieldsData}
    return newFields;
    
  }


  const InviteRaters = () => {
    const ratersData: any = { ...ratersFieldsData };
    let updatedRatersData: any = {};
    let filledRowCount = 0;
    Object.keys(ratersData).forEach((key: any) => {
      const data = ratersData[key];
      const { rows, otherDetails } = data;
      let filledTotalRows: any = rows.filter((row: any) => row.firstName || row.lastName || row.email).length;
      filledRowCount = filledTotalRows + filledRowCount;
      const newFields = validateRows(rows, otherDetails);
      updatedRatersData = newFields
    });
    if (filledRowCount < minTotal) {
      const errMsg = `You must add the minimum number of raters in each category`;
      setError(errMsg)
    } else {
      const errMsg = ''
      setError(errMsg);
    }

    setRatersFieldsData(updatedRatersData);
  }


  return (
    <div className="mainWrapper">
      <div className="dashboardPage">
        <AuthHeader title={title} headerLogo={getHeaderLogo(globalState)} setLoader={setShowLoader} />
        <div className="authHeaderBanner" style={{ background: `url(${getHeaderImg(globalState)}) no-repeat`, backgroundPosition: 'right', backgroundColor: '#F6F7F8' }}>
          <div className="authHeaderBannerContent">
            <p className="authHeaderBannerWelcomeContent">
              <Button
                text="Home"
                variant={ButtonVariant.SystemUI}
                classNames="backButton"
                onClick={() => alert('d')}
                transparent={true}
                iconProps={{ path: IconName.mdiChevronLeft, color: '#BA3A19' }}
              />
            </p>
            <h2 className="header2Style">Assign Raters for 360 Assessment</h2>
          </div>
        </div>
        {ratersFieldsData &&
          <div className="middleWrapper">
            <div className="messageNote">*Required</div>
            <GenerateSection
              fieldsData={ratersFieldsData}
              fieldState={setRatersFieldsData}
            />
          </div>
        }
        <>
          {error && <div className="sectionValidation">
            <Icon path={IconName.mdiInformation} color="#891202" />
            <span className="sectionErrmsg">{error}</span>
          </div>}

          <div className="actionItems">
            <Button
              text="Send Invites To Raters"
              variant={ButtonVariant.Secondary}
              classNames="ratersButton"
              style={secondaryButtonStyle}
              onClick={() => {
                InviteRaters()
              }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />

            <Button
              text="Send Invites & Start Assessments"
              variant={ButtonVariant.Secondary}
              classNames="ratersButton"
              style={secondaryButtonStyle}
              onClick={() => {
                alert('hi')
              }}
              iconProps={(showLoader) ? { path: IconName.mdiLoading, spin: true } : undefined}
              alignIcon={ButtonIconAlign.Right}
              disabled={showLoader}
            />
          </div>
        </>
        <AuthFooter />
      </div>
    </div>
  );
}

export default InviteRaters;