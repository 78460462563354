import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Api from "../Api";
import Loader from "../commons/Loader";
import { getToken } from "../commons/commonFunctions";

const InviteVerify = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<any>(false);

  let subDomain: any =
    window.location.host === "localhost:3000"
      ? "validation.test-leaders.heidricknavigator.com"
      : window.location.host;

  const token = getToken(window.location.href);
  const payload = {
    token: token,
    subDomain: subDomain,
  };
  useEffect(() => {
    Api.inviteVerify(payload).then((response: any) => {
      if (response?.success) {
        const data = response?.response?.data?.data;
        const type = response?.response?.data?.data?.profileType;
        const oktaAccountExists = data?.oktaAccountExists;
        const token = data?.authentication?.accessToken;
        setError(false);
        if (
          type === "Employee" ||
          (type === "Candidate" && oktaAccountExists === true)
        ) {
          navigate("/");
        } else if (type === "Candidate" && oktaAccountExists === false) {
          navigate(`/verify-email?token=${token}`);
        }
      } else {
        setError(true);
      }
    });
  }, []);
  return (
    <>
      {!error ? (
        <Loader />
      ) : (
        <h1 className="inviteVerifyError">Unauthorized Access</h1>
      )}
    </>
  );
};

export default InviteVerify;
