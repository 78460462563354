import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonVariant,
  IconName,
  ButtonIconAlign,
  TextInputWidth,
  LabelSize,
  TextInput,
} from "@eightfold.ai/octuple";
import Header from "../commons/Header";
import { secondaryButtonStyle } from "../commons/commonStyles";
import Footer from "../commons/Footer";
import Api from "../Api";
import { globalContext } from "../store";
import { useNavigate } from "react-router";
import {
  getOnboardingImg,
  getHeaderLogo,
} from "../commons/commonFunctions";
import { Link, useSearchParams } from "react-router-dom";
import { validateFields, validationOptions } from "../ValidationSchema";

const VerifyEmail = () => {
  let [params] = useSearchParams();
  let navigate = useNavigate();
  let token = params?.get('token'); 
  const { globalState } = useContext(globalContext);
  const [email, setEmail] = useState<any>("");
  const [unauthorized, setUnauthorized] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const title = globalState?.clientData?.name;
  let subDomain: any =
    window.location.host === "localhost:3000"
      ? "validation.test-leaders.heidricknavigator.com"
      : window.location.host;

  useEffect(() => {
    if(!token){
      setUnauthorized(true);
    }
  }, [token])
  

  const confirmEmail = () => {
     setShowLoader(true);
     const emailMsg = validateFields.validate({ email: email ? email : '' }, validationOptions)?.error?.details[0]?.message;
       
     if (emailMsg) {
      setErrorMsg(emailMsg);
      setShowLoader(false);
      return;
    }
   
    const payload = {
      email: email,
      subDomain: subDomain,
    };

    Api.getEmailVerify(payload, token)
      .then((response: any) => {
        setShowLoader(false);
        if (response?.success) {
         
          navigate("/welcome");
        } else {
          if (response?.data?.detail) {
            setErrorMsg(response?.data?.detail);
          }
          if (response?.data?.message) {
            setErrorMsg(response?.data?.message);
          }
        }
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  return (
    <>
     { unauthorized ? <h1 className="inviteVerifyError">Unauthorized Access</h1>:
    <div className="mainWrapper">
      <div
        className="imgWrapper"
        style={{
          background: `url(${getOnboardingImg(globalState)}) no-repeat`,
        }}
      >
        &nbsp;
      </div>
      <div className="rightWrapper">
        <Header title={title} headerLogo={getHeaderLogo(globalState)} />
        <div className="contentWrapper">
          <h1 className="titleStyle">Verify Your Contact Information</h1>
          <div className="middleContent">
            <p className="welcomeContent">
              {" "}
              Enter the email address associated with your invitation and we
              will send you a temporary password to create a secure account.
              Please follow the steps in the email.
            </p>

            <div className="fieldWrap" style={{ width: "100%" }}>
              <TextInput
                htmlType="text"
                id="email"
                clearable
                labelProps={{
                  text: "Email Address",
                  size: LabelSize.Medium,
                }}
                maxlength={100}
                minlength={0}
                name="email"
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                }}
                inputWidth={TextInputWidth.fill}
                classNames="inputTextField"
                status={errorMsg ? "error" : "validating"}
              />
              {errorMsg && <span className="errorMessage">{errorMsg}</span>}
            </div>
            <div>
              <Button
                text="Confirm"
                variant={ButtonVariant.Secondary}
                classNames="secondaryButton"
                style={secondaryButtonStyle}
                onClick={() => {
                  confirmEmail();
                }}
                iconProps={
                  showLoader
                    ? { path: IconName.mdiLoading, spin: true }
                    : undefined
                }
                alignIcon={ButtonIconAlign.Right}
                disabled={showLoader}
              />
            </div>
            <p className="welcomeContent" style={{ alignSelf: "auto" }}>
              If you did not receive an email&nbsp;
              <Link
                style={{ color: "#1A212E" }}
                className="paragraphStyle"
                rel="noreferrer"
                to={"https://heidricksupport.zendesk.com/hc/en-us"}
                target="_blank"
              >
                Contact Us
              </Link>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>}
    </>
   
  );
};

export default VerifyEmail;
